/* vietnamese */
@font-face {
  font-family: '__Quicksand_ff8fe9';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/07b30c88f8c1c1ad-s.p.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Quicksand_ff8fe9';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/a1b64b733866887d-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Quicksand_ff8fe9';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/34a9823ac715e2c2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Quicksand_Fallback_ff8fe9';src: local("Arial");ascent-override: 95.87%;descent-override: 23.97%;line-gap-override: 0.00%;size-adjust: 104.31%
}.__className_ff8fe9 {font-family: '__Quicksand_ff8fe9', '__Quicksand_Fallback_ff8fe9';font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/76f85175de9485bf-s.p.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b27c706639ed0ce8-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fc6d8b10b7b1f644-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/85ae9b4f4e1f55b0-s.p.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/cc1f5fabab25995c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/aed5f820244f2ee5-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/8a07907b1c0a0fa7-s.p.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/20b7d82cb5f5783a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/842eba58994a5460-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c562c6e6b7975def-s.p.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f2b0e3876f711795-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d5d5675f0c147195-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/baa33d7d9c5a2855-s.p.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/bc2c09677d9b58cc-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/939d8b039e3953b7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/356ac0abdf755dba-s.p.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/e16da29b01c3a071-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Be_Vietnam_Pro_06e80c';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/21dca4c8c75d37e0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Be_Vietnam_Pro_Fallback_06e80c';src: local("Arial");ascent-override: 90.61%;descent-override: 24.01%;line-gap-override: 0.00%;size-adjust: 110.36%
}.__className_06e80c {font-family: '__Be_Vietnam_Pro_06e80c', '__Be_Vietnam_Pro_Fallback_06e80c';font-style: normal
}

@font-face {
	font-family: 'c4c-icon';
	src: url(/_next/static/media/c4c-icon.93efc983.eot);
	src: url(/_next/static/media/c4c-icon.93efc983.eot#iefix) format('embedded-opentype'),
		url(/_next/static/media/c4c-icon.64205b54.ttf) format('truetype'),
		url(/_next/static/media/c4c-icon.4e7be746.woff) format('woff'),
		url(/_next/static/media/c4c-icon.f6d3baf9.svg#c4c-icon) format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='c4c-'],
[class*=' c4c-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'c4c-icon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.c4c-right:before {
	content: '\e91d';
	color: #806ce8;
}
.c4c-add_regular:before {
	content: '\e900';
	color: #fff;
}
.c4c-add:before {
	content: '\e901';
	color: #fff;
}
.c4c-again:before {
	content: '\e902';
	color: #fff;
}
.c4c-calendar-edit:before {
	content: '\e903';
	color: #5c93ff;
}
.c4c-check:before {
	content: '\e904';
}
.c4c-close-circle:before {
	content: '\e905';
}
.c4c-close:before {
	content: '\e906';
	/* color: #fff; */
}
.c4c-crown:before {
	content: '\e907';
	color: #fff;
}
.c4c-danger:before {
	content: '\e908';
	color: #ffc804;
}
.c4c-document:before {
	content: '\e909';
	color: #fff;
}
.c4c-download:before {
	content: '\e90a';
}
.c4c-edit:before {
	content: '\e90b';
	color: #316bff;
}
.c4c-export:before {
	content: '\e90c';
}
.c4c-image:before {
	content: '\e90d';
}
.c4c-info-circle:before {
	content: '\e90e';
	color: #3dbc3a;
}
.c4c-lamp-on:before {
	content: '\e90f';
	color: #fff;
}
.c4c-menu:before {
	content: '\e910';
	color: #fff;
}
.c4c-paint:before {
	content: '\e911';
}
.c4c-play:before {
	content: '\e912';
}
.c4c-setting:before {
	content: '\e913';
}
.c4c-shuff:before {
	content: '\e914';
}
.c4c-sidebar-left:before {
	content: '\e915';
	color: #fff;
}
.c4c-sidebar-right:before {
	content: '\e916';
	color: #fff;
}
.c4c-sms-tracking:before {
	content: '\e917';
	color: #fff;
}
.c4c-subtitle:before {
	content: '\e918';
}
.c4c-trash:before {
	content: '\e919';
	color: #ff5757;
}
.c4c-upload:before {
	content: '\e91a';
}
.c4c-volume-off:before {
	content: '\e91b';
}
.c4c-volume-on:before {
	content: '\e91c';
}
.c4c-eye:before {
	content: '\e9ce';
}
.c4c-eye-blocked:before {
	content: '\e9d1';
}

