@font-face {
	font-family: 'c4c-icon';
	src: url('fonts/c4c-icon.eot?jyn4k0');
	src: url('fonts/c4c-icon.eot?jyn4k0#iefix') format('embedded-opentype'),
		url('fonts/c4c-icon.ttf?jyn4k0') format('truetype'),
		url('fonts/c4c-icon.woff?jyn4k0') format('woff'),
		url('fonts/c4c-icon.svg?jyn4k0#c4c-icon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='c4c-'],
[class*=' c4c-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'c4c-icon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.c4c-right:before {
	content: '\e91d';
	color: #806ce8;
}
.c4c-add_regular:before {
	content: '\e900';
	color: #fff;
}
.c4c-add:before {
	content: '\e901';
	color: #fff;
}
.c4c-again:before {
	content: '\e902';
	color: #fff;
}
.c4c-calendar-edit:before {
	content: '\e903';
	color: #5c93ff;
}
.c4c-check:before {
	content: '\e904';
}
.c4c-close-circle:before {
	content: '\e905';
}
.c4c-close:before {
	content: '\e906';
	/* color: #fff; */
}
.c4c-crown:before {
	content: '\e907';
	color: #fff;
}
.c4c-danger:before {
	content: '\e908';
	color: #ffc804;
}
.c4c-document:before {
	content: '\e909';
	color: #fff;
}
.c4c-download:before {
	content: '\e90a';
}
.c4c-edit:before {
	content: '\e90b';
	color: #316bff;
}
.c4c-export:before {
	content: '\e90c';
}
.c4c-image:before {
	content: '\e90d';
}
.c4c-info-circle:before {
	content: '\e90e';
	color: #3dbc3a;
}
.c4c-lamp-on:before {
	content: '\e90f';
	color: #fff;
}
.c4c-menu:before {
	content: '\e910';
	color: #fff;
}
.c4c-paint:before {
	content: '\e911';
}
.c4c-play:before {
	content: '\e912';
}
.c4c-setting:before {
	content: '\e913';
}
.c4c-shuff:before {
	content: '\e914';
}
.c4c-sidebar-left:before {
	content: '\e915';
	color: #fff;
}
.c4c-sidebar-right:before {
	content: '\e916';
	color: #fff;
}
.c4c-sms-tracking:before {
	content: '\e917';
	color: #fff;
}
.c4c-subtitle:before {
	content: '\e918';
}
.c4c-trash:before {
	content: '\e919';
	color: #ff5757;
}
.c4c-upload:before {
	content: '\e91a';
}
.c4c-volume-off:before {
	content: '\e91b';
}
.c4c-volume-on:before {
	content: '\e91c';
}
.c4c-eye:before {
	content: '\e9ce';
}
.c4c-eye-blocked:before {
	content: '\e9d1';
}
